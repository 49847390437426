.whiteCircle {

    background-color: white;
    border-radius: 100px;
    width: 180px;
    height: 180px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute ;
}

.modContainer {
    position: relative;
    padding-top: 30px;
    margin-top: 120px;
    


}

.modImage1 {

    padding-top: 33px;
}