.footer {
    background-color: #4a97d4;
    bottom: 0;
    color: white !important;
}

/* RESPONSIVE */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .footer {
        padding: 10px;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .footer {
        padding: 10px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .footer {
        padding: 10px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .footer {
        padding: 10px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {

        .footer {
            padding: 100px;
        }
  }

