.proContainer {
  position: relative;
  padding-top: 30px;
  background-size: cover !important;
}

.juan1 {
  opacity: 1;
  background-repeat: no-repeat;
  background-image: url("../../../static/images/Juan1.jpg");
}

.juan1:hover {
  background: linear-gradient(to bottom, transparent, black),
    url("../../../static/images/Juan1.jpg");
}

.maite {
  background: url("../../../static/images/Maite.jpg");
  opacity: 1;
  background-repeat: no-repeat;
}
.maite:hover {
  background: linear-gradient(to bottom, transparent, black),
    url("../../../static/images/Maite.jpg");
}

.matias {
  background: url("../../../static/images/matias.jpg");
  opacity: 1;
  background-repeat: no-repeat;
}
.matias:hover {
  background: linear-gradient(to bottom, transparent, black),
    url("../../../static/images/matias.jpg");
}


.domingo {
  background: url("../../../static/images/domingo.jpeg");
  opacity: 1;
  background-repeat: no-repeat;
}
.domingo:hover {
  background: linear-gradient(to bottom, transparent, black),
    url("../../../static/images/domingo.jpeg");
}

.benjamin {
  opacity: 1;
  background-repeat: no-repeat;
  background-image: url("../../../static/images/Benjamin1.jpg");
}

.benjamin:hover {
  background: linear-gradient(to bottom, transparent, black),
    url("../../../static/images/Benjamin1.jpg");
}

.juan2 {
  background: url("../../../static/images/Juan2.png");
  opacity: 1;
  background-repeat: no-repeat;
}

.juan2:hover {
  background: linear-gradient(to bottom, transparent, black),
    url("../../../static/images/Juan2.png");
}

.felipe {
  background: url("../../../static/images/felipe.jpeg");
  opacity: 1;
  background-repeat: no-repeat;
}

.felipe:hover {
  background: linear-gradient(to bottom, transparent, black),
    url("../../../static/images/felipe.jpeg");
}

.alma-picture-container {
  width: 300px;
  height: 300px;
  border-radius: 100%;
  text-align: center;
}

.bg-position-center {
  background-position: center;
  background-size: cover;
}
