.service-card {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 50px;
    display: inline-flex;
    white-space: bottom;
    border-radius: 15px !important;
    cursor: auto;
    font-family: 'Poppins' !important;

}

.horiCards {
    
    display: inline-flex;

}

.font-poppins2 {

font-display: 100%;
font-weight: 300;
}

.font-poppins {

    font-size: 20px;
    font-weight: bold;
}


img.MuiCardMedia-root {

    object-fit: fill;
}