@font-face {
  font-family: "MontaguSlab";
  font-style: normal;
  font-weight: normal;
  src: local("MontaguSlab"),
    url("../../static/fonts/MontaguSlab_96pt-SemiBold.ttf") format("truetype");
}

.jumbotron {
  background-image: url("../../static/images/JumboBg.jpg");
  max-height: 1000px;
  height: 140vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: black;
  position: relative;
}

.jumboFont {
  font-family: "MontaguSlab" !important;
  font-size: 16px;
}

.jumboButton {
  border-radius: 8px !important;
}

.jumboButton:hover {
  background-color: #37aa47 !important;
}

.h1 {
  margin: 10;
}
.h2 {
  margin: 100;
}
.h3 {
  margin: 10;
}

@media only screen and (max-width: 600px) {
  .jumboFont {
    font-family: "MontaguSlab" !important;
    font-size: 28px;
  }
  .jumbotron img {
    max-width: 100%; /* Reduce the image size for tablets */
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .jumboFont {
    font-family: "MontaguSlab" !important;
    font-size: 42px;
  }
  .jumbotron img {
    max-width: 100%; /* Reduce the image size for tablets */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .jumboFont {
    font-family: "MontaguSlab" !important;
    font-size: 48px;
  }
  .jumbotron img {
    max-width: 100%; /* Reduce the image size for tablets */
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .jumboFont {
    font-family: "MontaguSlab" !important;
    font-size: 50px;
  }
  .jumbotron img {
    max-width: 100%; /* Reduce the image size for tablets */
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .jumboFont {
    font-family: "MontaguSlab" !important;
    font-size: 68px;
  }
  .jumbotron img {
    max-width: 100%; /* Reduce the image size for tablets */
  }
}

.jumbotron-content {
  position: absolute;
  top: 50%; /* Vertically center content */
  left: 5%; /* Horizontally center content */
  color: white; /* Change text color to ensure readability */
  padding: 20px;
  font-size: 40px;
}
