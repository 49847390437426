@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins'), url('./static/fonts/Poppins-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    src: local('Poppins'), url('./static/fonts/Poppins-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: light;
    src: local('Poppins'), url('./static/fonts/Poppins-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: medium;
    src: local('Poppins'), url('./static/fonts/Poppins-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: lighter;
    src: local('Poppins'), url('./static/fonts/Poppins-ExtraLight.ttf') format('truetype');
  }

body {
  font-family: 'Poppins';
  background-color: #e9e4e1;
  padding-right: 0 !important;
}

.dark-blue {
  color: #273043;
}

.m-0 {
  margin: 0;
}

.font-poppins {
  font-family: 'Poppins' !important;
}

.color-white {
  color: #FFF;
}

a {
  color: inherit;
  text-decoration: inherit;
}


/* RESPONSIVE */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .general-padding {
    padding-left: 25px;
    padding-right: 25px;
  }
  h2 {
    font-size: 2.2em;
    font-weight: 900;
    margin: 0;
  }
  
  h3 {
    font-size: 1.4em;
    font-weight: 500;
    margin: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .general-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
  h2 {
    font-size: 3em;
    font-weight: 900;
    margin: 0;
  }
  
  h3 {
    font-size: 1.7em;
    font-weight: 500;
    margin: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .general-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
  h2 {
    font-size: 3em;
    font-weight: 900;
    margin: 0;
  }
  
  h3 {
    font-size: 1.7em;
    font-weight: 500;
    margin: 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .general-padding {
    padding-left: 70px;
    padding-right: 70px;
  }
  h2 {
    font-size: 3em;
    font-weight: 900;
    margin: 0;
  }
  
  h3 {
    font-size: 1.7em;
    font-weight: 500;
    margin: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .general-padding {
    padding-left: 100px;
    padding-right: 100px;
  }
  h2 {
    font-size: 3em;
    font-weight: 900;
    margin: 0;
  }
  
  h3 {
    font-size: 1.7em;
    font-weight: 500;
    margin: 0;
  }
}